body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: calc(2.5em + 4.2vmin);
  color: #f6f6f699;
  font-weight: 700;
  background-color: #000c;
}

code {
  line-height: 100%;
  color: black;
  font-weight: normal;
}


